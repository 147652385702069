import { RequestClient } from './requestClient'

export class Contact {
  constructor(id: string) {
    const form = document.getElementById(id) as HTMLFormElement

    if (!form) {
      return
    }

    this.setEventListener(form)
    this.submitFormListener(form)
    this.handleResponse(form)
  }

  static toggleSubmitButton(disable: boolean, form: HTMLFormElement): void {
    const btn = form.querySelector('button[type="submit"]') as HTMLButtonElement
    btn.disabled = disable
    btn.classList.toggle('disabled', disable)

    const icon = btn.querySelector('i')
    if (icon) {
      icon.classList.toggle('d-none', !disable)
    }
  }

  public handleResponse(form: HTMLFormElement): void {
    document.addEventListener('RequestFinishedEvent', (event: CustomEvent) => {
      const { status, data } = event.detail
      if (status !== 200) {
        document.querySelector('.alert-success')?.classList.add('d-none')

        form.classList.remove('needs-validation')
        form.classList.add('was-validated')

        Contact.toggleSubmitButton(false, form)

        data.errors.forEach(
          ({ id, message }: { id: string; message: string }) => {
            if (id === 'default') {
              document
                .querySelector('.alert-danger')
                ?.classList.remove('d-none')

              return
            }

            const element = form.querySelector(`#${id}`) as HTMLInputElement

            if (element) {
              element.nextElementSibling.textContent = message

              element.addEventListener('keyup', function () {
                if (this.validity.valid) {
                  this.classList.remove('invalid')
                } else {
                  this.classList.add('invalid')
                }
              })
            }
          }
        )

        const firstInvalid = form.querySelector('.invalid') as HTMLElement

        if (firstInvalid) {
          firstInvalid.focus()
        }
      } else {
        Contact.toggleSubmitButton(false, form)

        form.reset()
        form.style.display = 'none'
        form.querySelector('.invalid-feedback')?.classList.toggle('d-none')

        document.querySelector('.alert-success')?.classList.remove('d-none')
        document.querySelector('.alert-danger')?.classList.add('d-none')

        setTimeout(() => {
          form.style.display = ''
          document
            .querySelector('.invalid-feedback')
            ?.classList.toggle('d-none')

          document.querySelector('.alert-success')?.classList.add('d-none')
        }, 8000)
      }
    })
  }

  private setEventListener(form: HTMLFormElement): void {
    const textInputs = form.querySelectorAll('input')

    textInputs.forEach(input => {
      input.addEventListener('blur', function () {
        this.value = this.value.trim()
      })
    })
  }

  private submitFormListener(form: HTMLFormElement): void {
    form.addEventListener('submit', event => {
      event.preventDefault()

      Contact.toggleSubmitButton(true, form)
      const formData = new FormData(form)

      return RequestClient.send(
        'POST',
        '/email',
        formData,
        'RequestFinishedEvent'
      )
    })
  }
}
