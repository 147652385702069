export class RequestClient {
  static async send(
    method: string,
    uri: string,
    data: FormData,
    eventName: string
  ): Promise<void> {
    try {
      const response = await fetch(uri, {
        method: method,
        body: data,
      })

      const jsonData = await response.json()
      this.handleRequest(response.status, jsonData, eventName)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
      this.handleRequest(400, [{ errors: { general: 'error' } }], eventName)
    }
  }

  /**
   * Handles the request completion by dispatching a custom event with the response status and JSON data.
   *
   * @param status The HTTP status code of the response.
   * @param json The JSON data returned by the server.
   * @param eventName The name of the custom event to dispatch.
   */
  private static handleRequest(
    status: number,
    json: [{ success?: boolean; errors?: Record<string, string> }],
    eventName: string
  ): void {
    const event = new CustomEvent(eventName, {
      detail: {
        status: status,
        data: json,
      },
    })
    document.dispatchEvent(event)
  }
}
